import React, {useState, useEffect} from 'react';
// import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { API, graphqlOperation } from 'aws-amplify'
import {Subscription} from 'rxjs';
import {listZzoPages} from '../../graphql/queries'
import {onCreateZzoPage} from '../../graphql/subscriptions'
//import AddPage from '../../components/AddPage';

import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Page } from '../../model/Page';
import PageEditor from '../../components/PageEditor';
import NewsItems from '../../components/NewsItems';
import LinkItems from '../../components/LinkItems';
import PhotoGallery from '../../components/PhotoGallery';
import VideoGallery from '../../components/VideoGallery';
import ContentMaterial from '../../components/ContentMaterial';

import Spinner from '../../components/utils/Spinner';

const Admin = () => {
    // const [authState, setAuthState] = useState<AuthState>();
    const [key, setKey] = useState<any>('Home');
    const [galleryKey, setGalleryKey] = useState<any>('PhotoGallery');
    const [allPages, setAllPages] = useState<Page[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Authentication
    // useEffect(() => {
    //
    //     onAuthUIStateChange((nextAuthState, authData) => {
    //         setAuthState(nextAuthState);
    //         setUser(authData)
    //     });
    // }, []);

    // get all pages to render the tabs
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const result: any = await API.graphql(graphqlOperation(listZzoPages));
            let pages: Page[] = [];
            result.data.listZZOPages.items.forEach((element: any) => {
                pages.push(new Page(element.id, element.title, element.content));
            });
            setAllPages(pages);
        };
        fetchData();
        setIsLoading(false);
    }, []);

    // onCreatePageListener
    useEffect(() => {
        const createPageListener: Subscription = (API.graphql(graphqlOperation(onCreateZzoPage)) as any).subscribe({
                next: (pageData: any) => {
                    const newPage = pageData.value.data.onCreateZZOPage;
                    const prevPage = allPages?.filter( page => page.id !== newPage.id);
                    const updatedPages = [newPage, ...prevPage];
                    setAllPages(updatedPages);
                }
            })
        //createPageListener();
        return () => createPageListener.unsubscribe()
    }, [allPages]);

    return (
        <Container fluid className='mb-3 mt-3'>
            <Authenticator hideSignUp={true}>
            {({signOut, user}) => (
                <main>
                    <div>Hello, {user?.attributes?.email}</div>
                    <button onClick={signOut}>Sign out</button>
                    {isLoading && (<Spinner />)}
                    <Tabs defaultActiveKey={key} onSelect={(k) => setKey(k)} className="mb-3 mt-3">
                        {allPages?.map((item, i) => {
                            return (<Tab key={i} eventKey={item.title} title={item.title}>
                                <PageEditor page={item.title} pageId={item.id} content={item.content} />
                            </Tab>);
                        })}
                        <Tab eventKey="NewsItems" title="NieuwsItems">
                            <NewsItems />
                        </Tab>
                        <Tab eventKey="LinkItems" title="Links">
                            <LinkItems />
                        </Tab>
                        <Tab eventKey="Gallery" title="Galerij">
                            <Tabs defaultActiveKey={galleryKey} onSelect={(k) => setGalleryKey(k)}>
                                <Tab eventKey="PhotoGallery" title="Foto">
                                    <PhotoGallery />
                                </Tab>
                                <Tab eventKey="VideoGallery" title="Video">
                                    <VideoGallery />
                                </Tab>
                                <Tab eventKey="ContentMaterial" title="Content materiaal">
                                    <ContentMaterial />
                                </Tab>
                            </Tabs>
                        </Tab>
                        {/* <Tab eventKey="Toevoegen" title="Toevoegen">
                    <AddPage />
                </Tab> */}
                    </Tabs>
                </main>
            )}

            {/*<AmplifySignOut />*/}


        </Authenticator>
        </Container>
    ); //: (
    //     <Authenticator>
    //         <AmplifySignIn
    //             slot="sign-in"
    //             hideSignUp
    //         ></AmplifySignIn>
    //     </Authenticator>
    // );
}

export default Admin;
