import React, {Fragment} from 'react'
import Container from 'react-bootstrap/Container';
import PageViewer from '../../components/PageViewer'

const Prices = () => {
  return (
    <Fragment>
        <Container className="jumbotron mb-3 mt-3">
            <PageViewer title="Tarieven" />
            <div className="mb-3 mt-3">
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSet1Q9l_IXfilV9wtXQcX9Nfdy3OzrNjeTDCDzMjjTzwEzNeA/viewform?embedded=true"
                width="100%"
                title="inschrijving"
                height="520"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}>
                    Loading�
            </iframe>
        </div>
        </Container>
    </Fragment>
  );
};

export default Prices;
