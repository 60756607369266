/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createZzoPage = /* GraphQL */ `
  mutation CreateZzoPage(
    $input: CreateZZOPageInput!
    $condition: ModelZZOPageConditionInput
  ) {
    createZZOPage(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateZzoPage = /* GraphQL */ `
  mutation UpdateZzoPage(
    $input: UpdateZZOPageInput!
    $condition: ModelZZOPageConditionInput
  ) {
    updateZZOPage(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteZzoPage = /* GraphQL */ `
  mutation DeleteZzoPage(
    $input: DeleteZZOPageInput!
    $condition: ModelZZOPageConditionInput
  ) {
    deleteZZOPage(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const createZzoNews = /* GraphQL */ `
  mutation CreateZzoNews(
    $input: CreateZZONewsInput!
    $condition: ModelZZONewsConditionInput
  ) {
    createZZONews(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateZzoNews = /* GraphQL */ `
  mutation UpdateZzoNews(
    $input: UpdateZZONewsInput!
    $condition: ModelZZONewsConditionInput
  ) {
    updateZZONews(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteZzoNews = /* GraphQL */ `
  mutation DeleteZzoNews(
    $input: DeleteZZONewsInput!
    $condition: ModelZZONewsConditionInput
  ) {
    deleteZZONews(input: $input, condition: $condition) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const createZzoLink = /* GraphQL */ `
  mutation CreateZzoLink(
    $input: CreateZZOLinkInput!
    $condition: ModelZZOLinkConditionInput
  ) {
    createZZOLink(input: $input, condition: $condition) {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateZzoLink = /* GraphQL */ `
  mutation UpdateZzoLink(
    $input: UpdateZZOLinkInput!
    $condition: ModelZZOLinkConditionInput
  ) {
    updateZZOLink(input: $input, condition: $condition) {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteZzoLink = /* GraphQL */ `
  mutation DeleteZzoLink(
    $input: DeleteZZOLinkInput!
    $condition: ModelZZOLinkConditionInput
  ) {
    deleteZZOLink(input: $input, condition: $condition) {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
