import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { API, graphqlOperation } from 'aws-amplify';
import {deleteZzoLink} from '../../../graphql/mutations';

const Viewer = (props: any) => {
    const onDeleteItemHandler = async (id: string) => {
        const input = {
            id: id
        };
        await API.graphql(graphqlOperation(deleteZzoLink, {input}));
    }
    return (
        <Row key={props.id}>
          <Col xs={6}><p>{props.title}</p></Col>
          <Col xs={6}><p><button className="btn btn-danger" onClick={(e) => onDeleteItemHandler(props.id) }><i className="fas fa-trash-alt"></i></button></p></Col>
        </Row>
    )
}

export default Viewer
