import React from 'react';

import { Parallax } from 'react-parallax';
import Logo from '../Logo';

import banner from '../../../assets/header2.png';

const Header = () => {
  return (
    <Parallax bgImage={banner} blur={0} bgImageStyle={{ width: '100%', height: 'auto', bottom: '-95%' }} strength={400}>
      <Logo />
    </Parallax>
  );
};

export default Header;
