import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import './App.scss';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import OWH from './pages/OWH';
import Prices from './pages/Prices';
import NewsItems from './pages/NewsItems';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import LinkItems from './pages/LinkItems';
import ImageGallery from './pages/ImageGallery';
import NotFound from './pages/NotFound';
import Admin from './pages/Admin';

import awsconfig from './aws-exports';
import Header from './components/layout/Header';
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import NewsItem from "./pages/NewsItem";
import ClubAPI from "./pages/ClubAPI";

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/aboutUs' element={<AboutUs/>} />
          <Route path="/owh" element={<OWH/>}  />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/prices" element={<Prices/>} />
          <Route path="/news" element={<NewsItems/>} />
          <Route path='/news/:id' element={<NewsItem  />} />
          <Route path="/gallery" element={<ImageGallery/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/clubapi" element={<ClubAPI/>} />
          <Route path="/links" element={<LinkItems/>} />
          <Route path="/admin" element={<Admin/>} />
          <Route path="/*" element={<NotFound/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;