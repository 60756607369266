/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateZzoPage = /* GraphQL */ `
  subscription OnCreateZzoPage {
    onCreateZZOPage {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZzoPage = /* GraphQL */ `
  subscription OnUpdateZzoPage {
    onUpdateZZOPage {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZzoPage = /* GraphQL */ `
  subscription OnDeleteZzoPage {
    onDeleteZZOPage {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZzoNews = /* GraphQL */ `
  subscription OnCreateZzoNews {
    onCreateZZONews {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZzoNews = /* GraphQL */ `
  subscription OnUpdateZzoNews {
    onUpdateZZONews {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZzoNews = /* GraphQL */ `
  subscription OnDeleteZzoNews {
    onDeleteZZONews {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZzoLink = /* GraphQL */ `
  subscription OnCreateZzoLink {
    onCreateZZOLink {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZzoLink = /* GraphQL */ `
  subscription OnUpdateZzoLink {
    onUpdateZZOLink {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZzoLink = /* GraphQL */ `
  subscription OnDeleteZzoLink {
    onDeleteZZOLink {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
