import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import {Storage} from "aws-amplify";
// import { AccessLevel } from '@aws-amplify/ui-components'

import AddVideo from './AddVideo';
import VideoViewer from './VideoViewer';

import Spinner from '../utils/Spinner';

const VideoGallery = () => {
  const [allVideos, setAllVideos] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async() => {
    setIsLoading(true);
    const response: any = await Storage.list('video/', {level: 'public' }) as any;
    setAllVideos(response.results);
    setIsLoading(false);
  }

  const onDeleteItemHandler = (id: string) => {

  }
  return (
    <Container className="mb-3 mt-3">
      <AddVideo allVideos={allVideos} setAllVideos={setAllVideos} />
      <Container className="mb-3 mt-3">
        {isLoading && <Spinner />}
        <ListGroup>
        {allVideos?.map((item: any, i: number) => {
        return (<ListGroup.Item key={i}>
          <VideoViewer storageKey={item.key} onDelete={() => onDeleteItemHandler(item.key)} />
        </ListGroup.Item>)
      })}
      </ListGroup>
      </Container>

    </Container>
  )
}

export default VideoGallery;
