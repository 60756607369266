import React, {useState, useEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import {listZzoNewss} from '../../graphql/queries';
import {onCreateZzoNews, onDeleteZzoNews} from '../../graphql/subscriptions';
import Container from 'react-bootstrap/Container';
import {Subscription} from 'rxjs'

import Editor from './Editor';
import Viewer from './Viewer';
import Spinner from '../utils/Spinner';

const NewsItems = () => {
    const [allNews, setAllNews] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result: any = await API.graphql(graphqlOperation(listZzoNewss));
            setAllNews(result.data.listZZONewss.items);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    // Listen to the onCreateNews
    useEffect(() => {
        const createNewsListener: Subscription = (API.graphql(graphqlOperation(onCreateZzoNews)) as any).subscribe({
                next: (newsData: any) => {
                    const newItem = newsData.value.data.onCreateZZONews;
                    const prevNews = allNews?.filter( (news: any) => news.id !== newItem.id);
                    const updatedNews: any[] = [newItem, ...prevNews];
                    setAllNews(updatedNews);
                }
            });
        return () => createNewsListener.unsubscribe();
    }, [allNews]);

    //Listen to the onDeleteNews
    useEffect(() => {
        const deleteNewsListener: Subscription = (API.graphql(graphqlOperation(onDeleteZzoNews)) as any).subscribe({
                next: (newsData: any) => {
                    const deletedNews = newsData.value.data.onDeleteZZONews;
                    const updatedNews: any[] = allNews?.filter((news: any) => news.id !== deletedNews.id);
                    setAllNews(updatedNews);
                }
            })
        return () => deleteNewsListener.unsubscribe();
    }, [allNews]);

    // sort function by Date
    const sortByDate = (a: any, b: any) =>  {
        if( a.date < b.date) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        return 0;
    }
    return (
        <Container className="mb-3 mt-3">
            <Editor />
            {isLoading && <Spinner />}
            {allNews.sort(sortByDate).map((item: any, i) => {
                return <Viewer key={i} id={item.id} date={item.date} title={item.title}  />
            })}
        </Container>
    )
}

export default NewsItems
