import React, {useState, useEffect} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import { AccessLevel } from '@aws-amplify/ui-components';
import {Storage} from 'aws-amplify';
import AddImage from './AddImage';
import PhotoViewer from './PhotoViewer';

import Spinner from '../utils/Spinner';
import Col from "react-bootstrap/Col";

const PhotoGallery = () => {

  const [allImages, setAllImages] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    setIsLoading(true);
    const response = await Storage.list('gallery/', {level: 'public' }) as any;
    setAllImages(response.results);
    setIsLoading(false);
  }

  const onDeleteItemHandler = async (id: string) => {
    try {
      await Storage.remove(id);
      const updatedList = allImages.filter((item: any) => item.key !== id);
      setAllImages([...updatedList]);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container className="mb-3 mt-3">
      <AddImage allImages={allImages} setAllImages={setAllImages} />
      <Row className="mb-3 mt-3 g-4" xs={1} md={6} >
        {isLoading && <Spinner />}
        {allImages?.map((item: any, i: number) => {
          return (<Col key={i}>
            <PhotoViewer storageKey={item.key} onDelete={() => {onDeleteItemHandler(item.key)}} />
          </Col>);
        })}
      </Row>
    </Container>
  )
}

export default PhotoGallery
