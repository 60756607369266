import React from 'react'
import Container from 'react-bootstrap/Container';
import PageViewer from '../../components/PageViewer'

const OWH = () => {
  return (

    <Container className="jumbotron mb-3 mt-3">
      <PageViewer title="Wat is OWH?" />
    </Container>
  );
};

export default OWH;
