import React, {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import {Storage} from "aws-amplify";
// import { AccessLevel } from '@aws-amplify/ui-components'

import Spinner from '../../utils/Spinner';

const AddImage = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = async (file: any) => {
    setIsLoading(true);
    const result: any = await Storage.put('gallery/' + file.name, file, {
      contentType: 'image/png',
      level: 'public'
    } )
    props.setAllImages([result, ...props.allImages]);
    setIsLoading(false);
  }
  return (
    <Container>
      <Form>
        <Form.Control
            type='file'
          id="custom-image"
          // label="Voeg hier een afbeelding toe"
          // custom
          accept="image/*"
          onChange={(e: any) => onChange(e.target.files[0])}
          disabled={isLoading}
        />
      </Form>
      {isLoading && <Spinner />}
    </Container>
  )
}

export default AddImage
