import React from 'react';
import Card from 'react-bootstrap/Card';
import DOMPurify from 'dompurify';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import Row from "react-bootstrap/Row";

const NewsCard = (props: any) => {
    return (
        <Card key={props.id}>
            <Card.Header>
                <Container>
                    <Row xs={1} md={2}>
                        <Col>{props.title}</Col>
                        <Col>
                            <div style={{float: 'right'}}>
                                <FacebookShareButton
                                    url={'https://www.owhbrugge.be/news/' + props.id}
                                    quote={props.title}
                                    hashtag={"#owhbrugge"}
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
                <Card.Text dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(props.content)}}></Card.Text>
            </Card.Body>
            <Card.Footer>
            <small className="text-muted">{props.date}</small>
            </Card.Footer>
        </Card>
    )
}

export default NewsCard
