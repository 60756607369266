import React, {useEffect, useState} from 'react';
import {Storage} from 'aws-amplify';

// import { AccessLevel } from '@aws-amplify/ui-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from '../../components/utils/Spinner';
import MediaCard from '../../components/MediaCard';

const ImageGallery = () => {
  const [allMedia, setAllMedia] = useState<null |any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let media: any[] = [];
      const images: any = await Storage.list('gallery/', {level: 'public', pageSize: 200});

      for(let i = 0; i < images.results.length; i++) {
        media.push(images.results[i]);
      }
      const videos: any = await Storage.list('video/', {level: "public", pageSize: 200});
      for(let i = 0; i < videos.results.length; i++) {
        media.push(videos.results[i]);
      }
      setAllMedia(media);
      setIsLoading(false);
    }
    fetchData();
  }, []);
  const sortByName = (a: any, b: any) => {
    const modifiedA = a.key.substring(a.key.indexOf('/'));
    const modifiedB = b.key.substring(b.key.indexOf('/'));

    if( modifiedA < modifiedB) {
        return 1;
    }
    if (modifiedA > modifiedB) {
        return -1;
    }
    return 0;
  }
  return (
    <Container className="mb-3 mt-3">
       {isLoading && <Spinner />}
      <Row xs={1} md={3} className="g-4">
        {allMedia?.sort(sortByName).map((item, i) => {
          return (
              <Col key={i}>
                <MediaCard mediaKey={item.key} />
              </Col>
          )
        })}

      </Row>


    </Container>
  )
}

export default ImageGallery
