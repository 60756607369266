import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import { API, graphqlOperation } from 'aws-amplify';
import {createZzoLink} from '../../../graphql/mutations';

import Form from 'react-bootstrap/Form';

import Spinner from '../../utils/Spinner';

const Editor = () => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onChange = (e: any) => {
        setValue(e.target.name, e.target.value);
      }

      const onSubmit = async (data: any) => {
        setIsLoading(true);
        const input = {
            title: data.title,
            imageUrl: data.imgUrl,
            link: data.link
        }
        await API.graphql(graphqlOperation(createZzoLink, {input}))
        reset();
        setIsLoading(false);
      }
    return (
        <Form>
            <Form.Group>
            <Form.Label>Titel van de link</Form.Label>
            <Form.Control type="text" {...register('title', {required: true})}onChange={onChange} ></Form.Control>
            </Form.Group>
            <Form.Group>
            <Form.Label>URL naar een afbeelding</Form.Label>
            <Form.Control type="text" {...register('imgUrl', {required: true})}  onChange={onChange} ></Form.Control>
            </Form.Group>
            <Form.Group>
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" {...register('link', {required: true})} name="link" onChange={onChange}></Form.Control>
            </Form.Group>
            <Form.Group>
            <button className="btn btn-success" onClick={handleSubmit(onSubmit)} type="submit" disabled={isLoading}>
                Linkitem toevoegen
            </button>
            {isLoading && <Spinner />}
            </Form.Group>
        </Form>
    )
}

export default Editor
