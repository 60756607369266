import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer = () => {
    return (
        <footer className="bg-primary ">
            <Container className="text-center text-white">
                <small>
                Designed by{' '}
                <a href="https://www.denegee.be" target="_blank" className="text-white" rel="noopener noreferrer">
                    DenEgee
                </a>
                </small>
            </Container>
        </footer>
    )
}

export default Footer
