import React, {useState, Fragment} from 'react';
import {useForm} from 'react-hook-form';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

const ContactForm = () => {
    const { register,  formState: { errors }, handleSubmit, setValue, reset } = useForm();
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const onChange = (e: any) => {
      setValue(e.target.name, e.target.value);
    }
  
    const onSubmit = async (data: any) => {
      setLoading(true);
      data.subject = "Bericht voor ZZO";
      data.from = process.env.REACT_APP_FORM_NOREPLY;
      data.destination = String(process.env.REACT_APP_FORM_SENTTO_EMAIL);
  
      let message = '';
      message += 'Naam + Voornaam: ' + data.naam + ' ' + data.voornaam + '<br />';
      message += 'Woonplaats: ' + (data.woonplaats !== '' ? data.woonplaats : 'niet ingegven') + '<br />'
      message += 'Bericht: <br />' + data.bericht;
  
      data.message = message;
  
      try {
        await axios.post(process.env.REACT_APP_MAILSERVER+"email", data);
      } catch (error) {
        console.error(error);
      }
      reset();
      setLoading(false);
      if (!showMessage) {
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 5000);
      }
    }
    return (
        <Fragment>
            <Form>
                <Form.Group>
                    <Form.Label>Naam</Form.Label>
                    <Form.Control type="text" placeholder="Naam"  {...register('naam', {required:true})} name="naam"  onChange={onChange}/>
                    {errors.naam && (
                    <Form.Text className="text-danger">
                    Het veld Naam is verplicht.
                    </Form.Text>)}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Voornaam</Form.Label>
                    <Form.Control type="text" placeholder="Voornaam" {...register('voornaam', {required:true})} onChange={onChange} />
                    {errors.voornaam && (
                    <Form.Text className="text-danger">
                    Het veld Voornaam is verplicht.
                    </Form.Text>)}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Woonplaats</Form.Label>
                    <Form.Control type="text" {...register('woonplaats')} placeholder="Woonplaats" onChange={onChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type="email" placeholder="E-mail" {...register('email', {required: true, pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} name="email"  onChange={onChange} />
                    {errors.email && (
                    <Form.Text className="text-danger">
                    Gelieve een geldige mail adres in te geven.
                    </Form.Text>)}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bericht</Form.Label>
                    <Form.Control as="textarea" rows={3} {...register('bericht', {required:true})}  onChange={onChange} ></Form.Control>
                    {errors.bericht && (
                    <Form.Text className="text-danger">
                        Gelieve een bericht in te geven.
                    </Form.Text>)}
                </Form.Group>
                <Row>
                    <Col sm={8}>
                        <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>
                            Verzenden
                        </Button>
                    </Col>
                    <Col>
                    {loading && (
                        <Spinner animation="border" role="status" variant="info" style={{width: "2rem", height: "2rem"}}>
                            <span className="sr-only">Loading</span>
                        </Spinner>
                    )}
                    </Col>
                </Row>
            </Form>
            {showMessage && (
                <Container className="mb-1 mt-1">
                    <Alert variant="success">
                        Bedankt voor je bericht. We antwoorden je zo spoedig mogelijk
                    </Alert>
                </Container>
            )}
        </Fragment>
    )
}

export default ContactForm
