import React, {useState, useEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {Subscription} from 'rxjs'
import {listZzoLinks} from '../../graphql/queries';
import {onCreateZzoLink, onDeleteZzoLink} from '../../graphql/subscriptions';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from '../../components/LinkItems/Card';

import Spinner from '../../components/utils/Spinner';

const LinkItems = () => {
    const [allLinks, setAllLinks] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result: any = await API.graphql(graphqlOperation(listZzoLinks));
            setAllLinks(result.data.listZZOLinks.items);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    // onCreate Listener
    useEffect(()=> {
        const createLinkListener: Subscription = (API.graphql(graphqlOperation(onCreateZzoLink)) as any).subscribe({
            next: (linkData: any) => {
                const linkItem = linkData.value.data.onCreateZZOLink;
                const prevLinks = allLinks?.filter( (link: any) => link.id !== linkItem.id);
                const updatedLinks: any[] = [linkItem, ...prevLinks];
                setAllLinks(updatedLinks);
            }
        });
    return () => createLinkListener.unsubscribe();
    }, [allLinks]);

    // onDelete Listener
    useEffect(() => {
        const deleteLinkListener: Subscription = (API.graphql(graphqlOperation(onDeleteZzoLink)) as any).subscribe({
            next: (linkData: any) => {
                const deletedLink = linkData.value.data.onDeleteZZOLink;
                const updatedLinks: any[] = allLinks?.filter((link: any) => link.id !== deletedLink.id);
                setAllLinks(updatedLinks);
            }
        })
    return () => deleteLinkListener.unsubscribe();
    }, [allLinks]);
    return (
        <Container className="mb-3 mt-3">
            {isLoading && <Spinner />}
            <Row xs={1} md={3} className="g-4">
                {allLinks.map((item, i) => {
                    return (
                        <Col key={i}>
                            <Card id={item.id} link={item.link} title={item.title} imgUrl={item.imageUrl} />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default LinkItems
