import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {deleteZzoNews} from '../../../graphql/mutations';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Viewer = (props: any) => {

    const deleteNewsItemHandler = async (id: String) => {
        const input = {
            id: id
        };
        await API.graphql(graphqlOperation(deleteZzoNews, {input}));
    }
    return (
        <Row key={props.id}>
           <Col xs={3}>{props.date}</Col>
           <Col xs={6}>{props.title}</Col>
           <Col xs={3}><p><button className="btn btn-danger" onClick={(e) => deleteNewsItemHandler(props.id)}><i className="fas fa-trash-alt"></i></button></p></Col>
         </Row>
    )
}

export default Viewer;
