import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navigation = () => {
  const toggleResponsiveNavbar = () => {
    const element = document.getElementById('responsive-navbar-nav');
    if (element?.classList.contains('show')) {
      element.classList.remove('show');
    } else {
      element?.classList.add('show');
    }

  }
  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          {' '}
          <i className="fas fa-hockey-puck"></i>{' '}
          <span style={{ wordWrap: 'break-word' }}>OWH Brugge De Zwarte Zwanen</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            <Link className="nav-link" to="/" onClick={toggleResponsiveNavbar}>
              Home
            </Link>
            <NavDropdown title="De club" id="ourClub">
              <NavDropdown.Item>
                <Link to="/aboutUs" onClick={toggleResponsiveNavbar}>Over ons</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link  to="/owh" onClick={toggleResponsiveNavbar}>Wat is OWH?</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/prices" onClick={toggleResponsiveNavbar}>Tarieven</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Link className="nav-link" to="/news" onClick={toggleResponsiveNavbar}>
              Agenda
            </Link>
            <Link className="nav-link" to="/gallery" onClick={toggleResponsiveNavbar}>
              Galerij
            </Link>
            <NavDropdown title="Contact" id="contact">
              <NavDropdown.Item>
                <Link to="/contact" onClick={toggleResponsiveNavbar}>Contact</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/privacy" onClick={toggleResponsiveNavbar}>Privacy</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Link className="nav-link" to="/clubapi" onClick={toggleResponsiveNavbar}>
              API
            </Link>
            <Link className="nav-link" to="/links" onClick={toggleResponsiveNavbar}>
             Links
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
