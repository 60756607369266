import React, {Fragment, useState, useEffect} from 'react';
import {Storage} from 'aws-amplify';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

const MediaCard = (props: any) => {
  const [mediaUrl, setMediaUrl] = useState<any>();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const fetchData = async() => {
      const result: any = await Storage.get(props.mediaKey);
      setMediaUrl(result);
    }
    fetchData();
  }, [props.mediaKey]);

  let content;

  if( props.mediaKey.toString().startsWith('gallery/')) {
    content = (<Fragment>
      <Card>
        <Card.Img src={mediaUrl}  onClick={() => setShow(!show)} />
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        size="lg"
      >
        <Modal.Body>
          <Image src={mediaUrl} fluid />
        </Modal.Body>
      </Modal>
    </Fragment>)
  } else if (props.mediaKey.toString().startsWith('video/')) {
    content = (<Fragment>
      <Card>
        <Card.Body className="text-center">
          <Button className="mb-3 mt-3" variant="outline-primary" onClick={(e: any) => setShow(!show)}>
            <i className="far fa-play-circle fa-9x"></i>
          </Button>
        </Card.Body>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        size="lg"
      >
        <Modal.Body>
           <video
            id={props.mediaKey}
            src={mediaUrl}
            controls
            width="100%"
            autoPlay
          />
        </Modal.Body>
      </Modal>
    </Fragment>)
  }

  return (<Fragment>{content}</Fragment>)
}

export default MediaCard
