import React from 'react'
import Container from 'react-bootstrap/Container';
import PageViewer from '../../components/PageViewer'

const AboutUs = () => {
    return (
        <Container className="jumbotron mb-3 mt-3">
            <PageViewer title="Over ons" />
        </Container>
    )
}

export default AboutUs
