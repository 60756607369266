import React from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

const CustomSpinner = () => {
  return (
    <Container>
      <Spinner animation="grow" variant="warning" style={{width: "2rem", height: "2rem"}} />
    </Container>
  )
}

export default CustomSpinner
