import React, {Fragment, useState} from 'react'
import Form from 'react-bootstrap/Form';
import DOMPurify from 'dompurify';

import { API, graphqlOperation } from 'aws-amplify';

import {updateZzoPage} from '../../graphql/mutations';

// import Editor from '../utils/Editor';
import Spinner from '../utils/Spinner';
import {Editor} from "@tinymce/tinymce-react";

const PageEditor = (props: any) => {
    const [data, setData] = useState<null | any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChange = (content: any, editor: any) => {
      setData(content);
    }

    const onSubmit = async (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      
      const input = {
        id: props.pageId,
        title: props.page,
        content: DOMPurify.sanitize(data, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })
      }
      await API.graphql(graphqlOperation(updateZzoPage, {input}));
      setIsLoading(false);
    }
    return (
        <Fragment>
            <Form>
            <Form.Group>
                <Form.Label><h1>{props.page}</h1></Form.Label>
                {/*<Editor data={props.content} onChange={onChange} setData={setData} editorRef={editorRef} />*/}
                <Editor
                    apiKey='5j17gl15qabh112rj48lmha0tdncf9lq53glnndwzhw38qr7'
                    initialValue={props.content}
                    value={data}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                    onEditorChange={onChange}
                />
            </Form.Group>
            <Form.Group>
                <button className="btn btn-success" onClick={onSubmit} type="submit" disabled={isLoading}>
                Update {props.page}
                </button>
                {isLoading && <Spinner />}
            </Form.Group>
            </Form>
      </Fragment>
    )
}

export default PageEditor