/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getZzoPage = /* GraphQL */ `
  query GetZzoPage($id: ID!) {
    getZZOPage(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const listZzoPages = /* GraphQL */ `
  query ListZzoPages(
    $filter: ModelZZOPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZZOPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZzoNews = /* GraphQL */ `
  query GetZzoNews($id: ID!) {
    getZZONews(id: $id) {
      id
      title
      content
      date
      createdAt
      updatedAt
    }
  }
`;
export const listZzoNewss = /* GraphQL */ `
  query ListZzoNewss(
    $filter: ModelZZONewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZZONewss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZzoLink = /* GraphQL */ `
  query GetZzoLink($id: ID!) {
    getZZOLink(id: $id) {
      id
      title
      link
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const listZzoLinks = /* GraphQL */ `
  query ListZzoLinks(
    $filter: ModelZZOLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZZOLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        link
        imageUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
