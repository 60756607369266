import React from 'react'
import Container from 'react-bootstrap/Container';
import ContactForm from '../../components/ContactForm';
import PageViewer from '../../components/PageViewer'

const Contact = () => {
  return (

    <Container className="jumbotron mb-3 mt-3">
      <PageViewer title="Contact" />
      <Container className="mb-3 mt-3">
          <ContactForm />
      </Container>
    </Container>
  );
};

export default Contact;
