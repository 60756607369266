import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import {Storage} from "aws-amplify";
// import { AccessLevel } from '@aws-amplify/ui-components'

import Spinner from '../../utils/Spinner';

const AddVideo = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = async (file: File) => {
    setIsLoading(true);

    const result: any = await Storage.put('video/' + file.name, file, {
      contentType: 'video/mp4',
      level: 'public'
    });
    props.setAllVideos([result, ...props.allVideos]);

    setIsLoading(false);
  }

  return (
    <Container>
      <Form>
        <Form.Control
            type='file'
          id="custom-video"
          // label="Voeg hier een video toe"
          // custom
          accept="video/*"
          onChange={(e: any) => onChange(e.target.files[0])}
          disabled={isLoading}
        />
      </Form>
      {isLoading && <Spinner />}
    </Container>
  )
}

export default AddVideo
