import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {API, graphqlOperation} from "aws-amplify";
import {getZzoNews} from "../../graphql/queries";

import DOMPurify from 'dompurify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const NewsItem = () => {
    const {id} = useParams();
    const [news, setNews] = useState<any>([]);

    useEffect(() => {
       const fetchData = async () => {
           const result: any = await API.graphql(graphqlOperation(getZzoNews, {id: id}));
           setNews(result.data.getZZONews);
       }
       fetchData();
    }, [id]);


    return (
        <Container className="mb-3 mt-3">
            <h1 className="text-center">{news.title}</h1>
            {(() => {
                if(news.image) {
                    return (
                        <Row>
                            <Col md={2}>
                                <Image fluid src={news.image} alt={news.title} style={{ width: '100%'}} />
                            </Col>
                            <Col>
                                <div className="" dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(news.content)}}></div>
                            </Col>
                        </Row>
                    )
                } else {
                    return <div className="" dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(news.content)}}></div>
                }
            })()}
        </Container>
    );
};

export default NewsItem;
