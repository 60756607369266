import React, {Fragment} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const VideoViewer = (props: any) => {
  return (
    <Fragment>
      <Row>
        <Col>{props.storageKey}</Col>
        <Col>
        <Button variant="danger" onClick={props.onDelete}>
          <i className="fas fa-trash"></i>
        </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default VideoViewer
