import React from 'react';

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import logo from '../../../assets/OWHBruggeNew.png';

const Logo = () => {
  return (
    <Container className="text-center">
      <Image fluid src={logo} alt="logo" style={{ width: '50%' }} />
    </Container>
  );
};

export default Logo;
