import React from 'react';

import Container from 'react-bootstrap/Container';

const NotFound = () => {
  return (
    <Container className="jumbotron mb-3 mt-3">
      <h1 className="text-danger">U probeert een pagina te bereiken die (nog) niet bestaat</h1>
      <p className="text-danger">Probeer een ander via de hoofdmenu</p>
    </Container>
  );
};

export default NotFound;