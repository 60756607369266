// this file contains custom queries that doesn't be generated by aws-amplify

export const getZzoPageByName = /* GraphQL */ `
query GetZzoPageByName($title: String!) {
  listZZOPages(filter: {
    title: {
      eq: $title
    }
  }){
    items {
      id
      title
      content
    }
  }
}
`;