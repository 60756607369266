import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
// import { AccessLevel } from '@aws-amplify/ui-components';
import {Storage} from 'aws-amplify';
import AddMaterial from './AddMaterial';
import ViewMaterial from './ViewMaterial';

import Spinner from '../utils/Spinner';

const ContentMaterial = () => {
  const [allMaterials, setAllMaterials] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getMaterial();
  }, []);

  const getMaterial = async () => {
    setIsLoading(true);
    const response = await Storage.list('material/', {level: 'public'}) as any;
    setAllMaterials(response.results);
    setIsLoading(false);
  }

  const onDeleteItemHandler = async (id: string) => {
    try {
      await Storage.remove(id);
      const updatedList = allMaterials.filter((item: any) => item.key !== id);
      setAllMaterials([...updatedList]);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Container className="mb-3 mt-3">
      <AddMaterial allMaterial={allMaterials} setAllMaterial={setAllMaterials} />
      <Container className="mb-3 mt-3">
        {isLoading && <Spinner />}
        <ListGroup>
          {allMaterials?.map((item: any, i: number) => {
            return (<ListGroup.Item key={i}><ViewMaterial storageKey={item.key} onDelete={() => onDeleteItemHandler(item.key)} /></ListGroup.Item>)
          })}
        </ListGroup>
      </Container>
    </Container>
  )
}

export default ContentMaterial
