import React, {useState, useEffect, Fragment} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Storage} from 'aws-amplify';

import Spinner from '../../utils/Spinner';

const PhotoViewer = (props: any) => {
  const [image, setImage] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fecthImage =  async () => {
      setIsLoading(true);
      const result = await Storage.get(props.storageKey);
      setImage(result);
      setIsLoading(false);
    };
    fecthImage();
  }, [props.storageKey]);
  return (
    <Fragment>
      {isLoading && <Spinner />}
      <Card>
        <Card.Body>
          <Card.Img src={image} />
        </Card.Body>
        <Card.Footer>
          <Button variant="danger" onClick={props.onDelete}>
            <i className="fas fa-trash"></i>
          </Button>
        </Card.Footer>
      </Card>
    </Fragment>
  )
}

export default PhotoViewer
