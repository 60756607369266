import React from 'react';
import Card from 'react-bootstrap/Card';

const LinkCard = (props: any) => {
    return (
        <Card id={props.id}>
            <Card.Header className="text-center bg-primary text-white">{props.title}</Card.Header>
            <Card.Img src={props.imgUrl} style={{width: 'auto'}}></Card.Img>
            <Card.Body className="text-center bg-primary">
                <Card.Link className="btn btn-info" href={props.link} target="_blank">{props.link}</Card.Link>
            </Card.Body>
        </Card>)
}

export default LinkCard
