import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import DOMPurify from 'dompurify';
import { API, graphqlOperation } from 'aws-amplify';
import {createZzoNews} from '../../../graphql/mutations';

import { Editor } from '@tinymce/tinymce-react';

import Form from 'react-bootstrap/Form';
import Spinner from '../../utils/Spinner';

const NewsItemEditor = () => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onChange = (e: any) => {
        setValue(e.target.name, e.target.value)
      }

      const onSubmit = async (data: any) => {
        setIsLoading(true);
        const input = {
            title: data.title,
            content: DOMPurify.sanitize(data.content),
            date: data.date
        }
        await API.graphql(graphqlOperation(createZzoNews, {input}));
        reset();
        setIsLoading(false);
      }
    return (
        <Form>
          <Form.Group>
            <Form.Label>Titel</Form.Label>
            <Form.Control type="text" {...register('title', {required: true})}  onChange={onChange} ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Datum</Form.Label>
            <Form.Control type="date" {...register('date', {required: true})}  onChange={onChange} ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Inhoud</Form.Label>
            <Editor
              // ref={node => register({name: 'content'})}
                {...register('content')}
              apiKey="5j17gl15qabh112rj48lmha0tdncf9lq53glnndwzhw38qr7"
              init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'image'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help | image',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              onEditorChange={(content: any, editor: any) => {
                setValue('content', content);
              }}
            />
          </Form.Group>
          <Form.Group>
            <button className="btn btn-success" onClick={handleSubmit(onSubmit)} type="submit" disabled={isLoading}>
              NieuwsItem toevoegen
            </button>
            {isLoading && <Spinner />}
          </Form.Group>
        </Form>
    )
}

export default NewsItemEditor
