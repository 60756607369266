import React, {useState, useEffect, Fragment} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import DOMPurify from 'dompurify';
import {getZzoPageByName} from '../../graphql/customQueries';
import {onUpdateZzoPage} from '../../graphql/subscriptions';
import { Page } from '../../model/Page';
import Spinner from 'react-bootstrap/Spinner';

import {Subscription} from 'rxjs'

const PageViewer = (props: {title: string}) => {
    const [page, setPage] = useState<null | Page>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // get specific page
    useEffect( () => {
        setIsLoading(true);
        const getPage = async (title: string) => {
            const result: any = await API.graphql(graphqlOperation(getZzoPageByName, {title: title}))
            setPage(new Page(result.data.listZZOPages.items[0].id, result.data.listZZOPages.items[0].title, result.data.listZZOPages.items[0].content));
        }
        getPage(props.title);
        setIsLoading(false);
    }, [props.title]);

    // listener to every update
    useEffect(() => {
        const updatePageListener: Subscription = (API.graphql(graphqlOperation(onUpdateZzoPage)) as any).subscribe({
                next: (pageData: any) => {
                    const updatePage = pageData.value.data.onUpdateZZOPage;
                    setPage(new Page(updatePage.id, updatePage.title, updatePage.content));
                }
            });
        return () => updatePageListener.unsubscribe();
    }, [])

    if(isLoading) {
        return (<Spinner animation="border" role="status" variant="info" style={{width: "2rem", height: "2rem"}}>
        <span className="sr-only">Loading</span>
    </Spinner>);
    }

    return (
        <Fragment>
            {page != null && <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(page.content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div>}
        </Fragment>
    )
}

export default PageViewer
