import React from 'react';
import PageViewer from "../../components/PageViewer";
import Container from "react-bootstrap/Container";

const ClubAPI = () => {
    return (
        <Container className="jumbotron mb-3 mt-3">
            <PageViewer title="Club-API" />
        </Container>
    );
};

export default ClubAPI;
